<template>
  <VDataTable
    :headers="headers"
    :items="teams"
    :items-per-page="10"
    class="elevation-1"
    :loading="loading"
  >
    <template v-slot:[`item.name`]="{ item }">
      <RouterLink
        :to="{
          name: 'TeamDashboard',
          query: { gid: item.gid, name: item.name }
        }"
      >
        {{ item.name }}
      </RouterLink>
    </template>
  </VDataTable>
</template>
<script>
import { useFiltersUrlSync } from "@/composables/useFiltersUrlSync";
import { ref, watch } from "@vue/composition-api";
export default {
  name: "TeamTable",
  props: {
    teams: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const formSchema = {
      name: { type: "String" }
    };

    // manage filters
    let formData = ref(props.form);

    watch(
      () => props.form,
      newValue => {
        formData.value = newValue;
      },
      { deep: true }
    );

    const { vuetifyOptions, options, updateForm } = useFiltersUrlSync(
      context,
      formData,
      formSchema,
      props.customVuetifyOptions
    );

    return {
      vuetifyOptions,
      options,
      formSchema,
      updateForm
    };
  }
};
</script>
