import useDatatableUrlSync from "vue-datatable-url-sync";
import { ref } from "@vue/composition-api";

export function useFiltersUrlSync(
  context,
  form,
  formSchema,
  customVuetifyOptions
) {
  const options = ref({
    page: 1,
    page_size: 10,
    ordering: [],
    ...customVuetifyOptions
  });

  const updateForm = newForm => {
    form.value = newForm;
    context.emit("update:form", newForm);
  };

  const configurations = {
    debounceTime: 400,
    updateFormFunction: updateForm
  };

  const fetchDatas = (queryParams, queryAsObject) => {
    context.emit("fetchDatas", { queryParams, queryAsObject });
  };

  const { vuetifyOptions } = useDatatableUrlSync(
    context.root.$route,
    context.root.$router,
    form,
    fetchDatas,
    options,
    formSchema,
    null,
    configurations
  );

  return {
    vuetifyOptions,
    options,
    updateForm
  };
}
