<template>
  <VContainer fluid class="pa-6 d-flex justify-center align-center">
    <VBtn
      color="#005499"
      dark
      @click="authentify"
      v-if="!getAccessToken"
      class="text-none"
    >
      {{ $t("loginAsana") }}
    </VBtn>
    <VContainer v-else fluid class="pa-0">
      <SocioBaseContent
        :with-divider="false"
        background-color="#fff"
        class="pa-6"
      >
        <template #header-title>
          <p class="font-weight-bold pa-0">
            {{ $t("projects") }}
          </p>
        </template>
        <template #subheader>
          <VCol cols="6">
            <VTextField
              v-model="form.name"
              class="pa-0"
              label="Filtrer par nom"
              outlined
            />
          </VCol>
        </template>
        <template #content>
          <TeamTable
            :teams="filtered_teams"
            :headers="headers"
            :loading="loading"
            :form.sync="form"
            :custom-vuetify-options="customVuetifyOptions"
            @fetchDatas="filterTeams"
          />
        </template>
      </SocioBaseContent>
      <VContainer fluid class="d-flex justify-end">
        <VBtn color="#005499" dark @click="logout" class="text-none">
          {{ $t("logoutAsana") }}
        </VBtn>
      </VContainer>
    </VContainer>
  </VContainer>
</template>

<script>
import TeamTable from "@/components/TeamTable.vue";
import asanaClient from "@/setup/asanaclient";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "TeamDisplay",
  components: {
    TeamTable,
  },
  data() {
    return {
      teams: [],
      filtered_teams: [],
      headers: [
        {
          text: "Nom du projet",
          align: "start",
          value: "name",
        },
      ],
      loading: true,
      form: {
        name: "",
      },
      customVuetifyOptions: {
        page_size: 15,
      },
    };
  },
  computed: {
    ...mapGetters("asanaCredentials", ["getAccessToken", "getRefreshToken"]),
    socio_id() {
      return process.env.VUE_APP_SOCIO_WORKSPACE_ID;
    },
  },
  methods: {
    ...mapMutations("asanaCredentials", ["setAsanaCredentials"]),
    async authentify() {
      const authorization_form = {
        client_id: asanaClient.app.clientId,
        redirect_uri: asanaClient.app.redirectUri,
        response_type: "code",
      };
      const params = new URLSearchParams(authorization_form).toString();
      window.location.href = `https://app.asana.com/-/oauth_authorize?${params}`;
    },
    async filterTeams({ queryAsObject }) {
      if (this.teams.length === 0) {
        const response = await asanaClient.teams.getTeamsForOrganization(
          this.socio_id,
          {
            limit: 100,
            // AC Uncomment this line when developping to get easier to read data. Leave commented when you commit for better performances
            // opt_pretty: true
          }
        );
        let teamsData = [];
        let page = response;
        do {
          teamsData = teamsData.concat(page.data);
          page = page._response.next_page ? await page.nextPage() : null;
        } while (page);
        this.teams = teamsData;
        this.loading = false;
      }
      if (queryAsObject.name) {
        this.filtered_teams = this.teams.filter((team) =>
          team.name.toLowerCase().includes(queryAsObject.name.toLowerCase())
        );
      } else {
        this.filtered_teams = this.teams;
      }
    },
    logout() {
      localStorage.removeItem("asanaCredentials");
      this.setAsanaCredentials({
        access_token: "",
        data: {},
        expires_in: 3600,
        refresh_token: "",
        token_type: "bearer",
      });
    },
  },
};
</script>

<i18n>
{
  "fr": {
    "loginAsana": "Connectez vous à Asana",
    "logoutAsana" : "Déconnexion Asana",
    "projects": "Projets"
  },
  "en": {
    "loginAsana": "Login Asana",
    "logoutAsana" : "Logout Asana",
    "projects": "Projects"

  }
}
</i18n>
